@media (max-width: 400px) {
  .categoryShowWrapper ul li {
    width: 342px !important;
    /* height: calc((50vw) * 0.5625); */
  }
  .movieTile .movieTileImage .moviePoster {
    width: 300px;
    height: 280px;
    padding: 25% 0;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    border: 1px solid hsla(0, 0%, 100%, 0.1);
    position: relative;
  }
}  
 .movieTile .movieTileImage .moviePoster {
    width: 200px;
    height: 312px;
    padding: 25% 0;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s;
    transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
    border: 1px solid hsla(0, 0%, 100%, 0.1);
    position: relative;
}


@media (min-width: 768px){
    .movieTile .movieTileImage .moviePoster {
        width: 200px;
        height: 312px;
        padding: 25% 0;
        background-size: 100% 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: box-shadow 0.3s, -webkit-transform 0.3s;
        transition: transform 0.3s, box-shadow 0.3s;
        transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
        border: 1px solid hsla(0, 0%, 100%, 0.1);
        position: relative;
    }
}

.movieTile .movieTileImage .moviePoster .FeNml {
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%), 0 20px 30px 0 rgb(0 0 0 / 20%);
    background-color: rgba(38, 38, 45, 0.5);
    transition: opacity 0.3s, border 0.3s;
}

.movieTile .movieTileImage .moviePoster {
    width: 100%;
    padding: 71.75% 0;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    border: 1px solid hsla(0, 0%, 100%, 0.1);
    position: relative;
  }
  
  .movieTile .movieTileImage .moviePoster .FeNml {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 20px 30px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(38, 38, 45, 0.5);
    transition: opacity 0.3s, border 0.3s;
  }
  
  .movieTile .movieTileImage .moviePoster .FeNml._3vtLa {
    opacity: 1;
  }
  @media (min-width: 768px) {
    .movieTile .movieTileImage .f0zcB {
      display: block;
    }
    .movieTile .movieTileImage .moviePoster {
      width: 379px;
      height: 312px;
      padding: 25% 0;
      background-size: 100% 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: transform 0.3s, box-shadow 0.3s;
      border: 1px solid hsla(0, 0%, 100%, 0.1);
      position: relative;
    }
  }
  
  .movieTile .movieTileImage .moviePoster .FeNml {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 20px 30px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(38, 38, 45, 0.5);
    transition: opacity 0.3s, border 0.3s;
  }
  
  .movieTile .movieTileImage .moviePoster .FeNml._3vtLa {
    opacity: 1;
  }

  @media (max-width: 767px) {
    .movieTextWrapper .movieTextFlex h3 .movieTextHeading {
      font-size: 1.16667rem;
      font-weight: 400;
    }
    .movieTile .movieTileImage .moviePoster {
      width: 100%;
      padding: 25% 0;
      background-size: 100% 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: transform 0.3s, box-shadow 0.3s;
      border: 1px solid hsla(0, 0%, 100%, 0.1);
      position: relative;
    }
  }
  @media (max-width: 500px) {
    .categoryShowWrapper ul li {
      width: 342px !important;
      /* height: calc((50vw) * 0.5625); */
    }
    .movieTile .movieTileImage .moviePoster {
      width: 320px;
      height: 310px;
      padding: 25% 0;
      background-size: 100% 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: transform 0.3s, box-shadow 0.3s;
      border: 1px solid hsla(0, 0%, 100%, 0.1);
      position: relative;
    }
}  



