.video-js .vjs-text-track {
  display: none;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 0.1em;
  text-transform: capitalize;
  /* Transparent black background, or fallback to all black (oldIE) */
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
}
.vjs-menu-item-text {
  text-transform: capitalize;
}
.vjs-texttrack-settings {
  display: none;
}
.vjs-menu-item-text {
  content: "Off";
}

.vjs-resolution-button .vjs-menu-button .vjs-icon-placeholder::before {
  font-family: VideoJS;
  content: "\f110";
  font-size: 1.8em;
  line-height: 1.67;
  background-image: no-repeat;
}
.videoSettingsIcon {
  background-repeat: no-repeat;
  box-sizing: initial;
  block-size: initial;
}

.vjs-text-track-display {
  z-index: 99999999 !important;
}

/* .vjs-resolution-button  {
    font-family: VideoJS;
    content: '\f110';
    font-size: 1.8em;
    line-height: 1.67;
    background-image: no-repeat;
  }  */
/* .video-js .vjs-big-play-button{
    display:unset;
  } */
