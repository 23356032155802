.videoSettingsIcon {
  object-fit: contain;
  width: 19px;
  /* width: 48px;
      height: 24px; */
}

span.vjs-resolution-button-label {
  display: none;
}

.closingButton {
  text-align-last: right;
  position: absolute;
  top: 18px;
  z-index: 10;
  right: 12px;
}

.closingButton .close-video-button:hover {
  width: 37px;
  opacity: 1 !important;
  transition: ease-out 0.5s;
  background: #001489;
  /* background: #fbcd37; */
}

@media only screen and (min-width: 1500px) {
  .closingButton {
    right: 25px;
  }
}
@media (min-width: 200px){
  .video_container{
    height: 350px;
    padding-top: 20%;
  }
  .closingButton {
    padding-top: 15%;
    padding-bottom: 3%;
    padding-right: 3%;
  }
}
@media (min-width: 500px){
  .video_container{
    height: 500px;
    padding-top: 15%;
  }
  .closingButton {
    padding-top: 12%;
    padding-bottom: 3%;
    padding-right: 3%;
  }
}
@media (min-width: 700px){
  .video_container{
    height: 600px;
    padding-top: 15%;
  }
  .closingButton {
    padding-top: 12%;
    padding-bottom: 3%;
    padding-right: 3%;
  }
}
@media (min-width: 960px){
  .video_container{
    height: 700px;  
    padding-top: 5%;
  }
  .closingButton {
    padding-top: 5%;
    padding-bottom: 3%;
    padding-right: 3%;
  }
}
@media (min-width: 1100px){
  .video_container{
    height: 800px;  
    padding-top: 5%;
  }
  .closingButton {
    padding-top: 5%;
    padding-bottom: 3%;
    padding-right: 3%;
  }
}
@media (min-width: 1200px){
  .video_container{
    height: 800px;
    padding-top: 6%;
  }
  .closingButton {
    padding-top: 6%;
    padding-bottom: 3%;
    padding-right: 3%;
  }
}
@media (min-width: 1500px){
  .video_container{
    height: 950px;
    padding-top: 6%;
  }
  .closingButton {
    padding-top: 7%;
    padding-bottom: 3%;
    padding-right: 3%;
  }
}
@media (min-width: 1700px){
  .video_container{
    height: 1050px;
    padding-top: 8%;
  }
  .closingButton {
    padding-top: 8%;
    padding-bottom: 3%;
    padding-right: 3%;
  }
}
@media (min-width: 2000px){
  .video_container{
    height: 1200px;
    padding-top: 8%;
  }
  .closingButton {
    padding-top: 8%;
    padding-bottom: 3%;
    padding-right: 3%;
  }
}
@media (min-width: 2300px){
  .video_container{
    height: 1410px;
    padding-top: 8%;
  }
  .closingButton {
    padding-top: 7%;
    padding-bottom: 3%;
    padding-right: 5%;
  }
}