.btn-success {
  color: #fff;
  background-color: red;
  border-color: red;
}

.btn-block {
  display: block;
  width: 100%;
  height: 50px;
}

/* .modal-footer>* {
    margin: .25rem;
} */

button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
}
/* .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    color: gray;
} */
