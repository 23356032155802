.sub__container {
  padding-top: 50px;
}

.subscribe-btn{
  background: none;
  color: #f2bd0f;
  border: 1px solid #f2bd0f;
  width: 170px;
  height: 50px;
  outline: none;
  margin-top: 35px;
  font-size: 17px;
  border-radius: 25px;
  transition-duration: 1s;
  margin-left: 25px;
}
.subscribe-btn:hover {
  background: #f2bd0f!important;
  color: #fff !important;
}
