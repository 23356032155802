/* body {
    color: #2c3e50;
    background: #ecf0f1;
  } */
  h1 {
    text-align: center;
  }
  .half {
    float: left;
    width: 100%;
    padding: 0 1em;
  }
  
  .tab {
    position: relative;
    margin-bottom: 1px;
    width: 100%;
    color: #fff;
    overflow: hidden;
  }
  .inputsl {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  label {
    position: relative;
    display: block;
    padding: 0 0 0 1em;
    background:#c1bfbf;
    font-weight: bold;
    line-height: 3;
    cursor: pointer;
  }
  
  .tab-content {
    max-height: 0;
    overflow: hidden;
    background:rgb(234, 232, 233,1);
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
  }
  
  .tab-content p {
    margin: 1em;
    color: black;
  }
  /* :checked */
  input:checked ~ .tab-content {
    max-height: 70em;
  }
  /* Icon */
  label::after {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 3em;
    height: 3em;
    line-height: 3;
    text-align: center;
    -webkit-transition: all .35s;
    -o-transition: all .35s;
    transition: all .35s;
  }
  input[type=checkbox] + label::after {
    content: "\2795";
  }
  input[type=checkbox]:checked + label::after {
    transform: rotate(315deg);
  }
  
.H1s{
  display:"flex";
   justify-content:"center";
    font-size:"3.7rem";
}
.row .lpFlexWrapper {
  display:"flex";
   justify-content:"center";
    width:"50%";
    margin-left:"25%";
    margin-bottom:"10%";
 }
@media (max-width: 468px) {
  .H1s{
    display:"flex";
     justify-content:"center";
      font-size:"2.7rem";
      padding-left: 5px !important;
      line-height: 1.5 !important;
  }
  .row .lpFlexWrapper {
   display:"flex";
    justify-content:"center";
     /* width:"50%"; */
     /* margin-left:"25%"; */
     margin-bottom:"10%";
  }
}
